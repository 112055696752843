import $ from 'jquery';

$(window).on('load', function () {
	/* URL要素に移動
	/*---------------------------------------------------------*/
	let localLink = window.location + '';
	if (localLink.indexOf('#') !== -1) {
		let hash = localLink.slice(localLink.indexOf('#'));
		let dataAnchor = $('[data-id="' + hash.slice(1) + '"]');
		let scrollTop = 0;
		if ($(hash).length) {
			scrollTop = $(hash).offset().top;
		} else if (dataAnchor.length) {
			scrollTop = dataAnchor.offset().top;
		}
		let headerHeight = $('header').innerHeight();
		$('html,body').animate({scrollTop: scrollTop - headerHeight}, 1000, 'swing');
	}
});

$('a[href*=\\#]:not([href=\\#])').on('click', function () {
	if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
		let $target = $(this.hash);
		$target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
		let offsetTop = $("header").height();
		if ($target.length) {
			let targetOffset = $target.offset().top - offsetTop;
			$('html,body').animate({scrollTop: targetOffset}, 1000);
			return false;
		}
	}
});
