import $ from 'jquery';

$(function () {
	let footer = $('#footer');
	let floatingBanner = $('#js-floating-banner');
	if (floatingBanner.length && footer.length) {

		$(window).on('load scroll', function () {
			let footerTop = window.settings.getOffsetTop(footer.get(0));
			if ($(window).scrollTop() >= (footerTop - window.innerHeight)) {
				floatingBanner.hide(0);
			} else {
				floatingBanner.show(0);
			}
		});

		$(".c-floating_banner__close").on('click', function () {
			$(".c-floating_banner").addClass('is-close');
		});

		$(".c-floating_banner__open").on('click', function () {
			$(".c-floating_banner").removeClass('is-close');
		});
	}
});
