import $ from 'jquery';

// Header scroll left
$(function () {
	let header = $('#header');
	let gnavbar = $('#js-gnav-bar');
	let gnav = $('#js-gnav');
	let bodyElem = $('body');

	if (!header.length) {
		return;
	}

	// Open/Close Gnav
	gnavbar.on('click', function () {
		gnav.toggleClass('is-open');
		gnavbar.toggleClass('is-open');
		if (gnavbar.hasClass('is-open')) {
			window.settings.bodyFixed(true);
		} else {
			window.settings.bodyFixed(false);
		}
	});

	// Header Event
	if (header.hasClass('is-header-fixed')) {
		$(window).on('scroll', function () {
			header.css('left', ($(window).scrollLeft() * -1) + 'px');
		});
	}

	$(window).on('resize-x', function () {
		if (window.settings.pc() && gnavbar.hasClass('is-open')) {
			gnav.removeClass('is-open');
			gnavbar.removeClass('is-open');
			window.settings.bodyFixed(false);
		}
	});

	$(window).on('resize-x-end', function () {
		if (window.settings.isBodyFixed()) {
			header.css('padding-right', window.settings.getScrollBarWidth() + 'px');
		}
	});

	bodyElem.on('body-fixed', function (ev, fixed, scrollBarWidth) {
		if (fixed) {
			header.css('padding-right', scrollBarWidth + 'px');
		} else {
			header.css('padding-right', '');
		}
	});
});
