import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';


// const slider1 = new Swiper('.js-slider01', {
// 	loop: true,
// 	slidesPerView: 1,
// 	pagination: {
// 		el: '.swiper-pagination',
// 	},
// 	navigation: {
// 		nextEl: '.swiper-button-next',
// 		prevEl: '.swiper-button-prev',
// 	},
// });


let caseSwiper01;
let slide01 = $(".js-slider01");
if (slide01.length) {
	caseSwiper01 = new Swiper('.js-swiper01', {
		slidesPerView: 1,
		loop: true,
		navigation: {
			nextEl: ".js-swiper-next01",
			prevEl: ".js-swiper-prev01",
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
	});
}
