import $ from 'jquery';

window.settings.matchHeight = {
	init: function (elements) {
		elements.each(function () {
			const wrap = this;
			const isRow = wrap.dataset.matchRow;
			const items = window.settings.matchHeight.groupItems(this);
			for (let i in items) {
				const item = items[i].items;
				window.settings.matchHeight.matchItems(item, isRow);
				$(window).on('resize-x', function () {
					window.settings.matchHeight.matchItems(item, isRow);
				});
			}
		});
	},

	groupItems: function (domElement) {
		let group = {};
		$('[data-match-item]', domElement).each(function () {
			let name = $(this).data('match-item');
			if (name === "") name = 'all';
			if (typeof group[name] !== 'object') {
				group[name] = {items: $()};
			}
			group[name].items.push(this);
		});
		return group;
	},

	matchItems: function (items, isRow) {
		let matchData = {};
		items.removeClass('is-matched').css('height', '');
		items.each(function () {
			const offsetTop = (isRow === '0') ? 'all' : window.settings.getOffsetTop(this);
			if (typeof matchData[offsetTop] !== 'object') {
				matchData[offsetTop] = {height: 0, items: $()};
			}
			if (this.offsetHeight > matchData[offsetTop].height) {
				matchData[offsetTop].height = this.offsetHeight;
			}
			matchData[offsetTop].items.push(this);
		});
		for (let i in matchData) {
			const data = matchData[i];
			if (data.height && data.items.length >= 2) {
				data.items.css('height', data.height + 'px');
				data.items.addClass('is-matched');
			}
		}
	}
};

$(window).on('load', function () {
	window.settings.matchHeight.init($('.js-match-height'));
});
